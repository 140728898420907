<template>
  <b-container>
    <b-card class="mt-3" style="margin-bottom:100px;">
      <app-head />
      <b-row>
        <b-col>
          <app-impressum />
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
// @ is an alias to /src
import Impressum from "@/components/Impressum.vue";
import Head from "@/components/Head.vue";

export default {
  name: "Impressum",
  components: {
    appImpressum: Impressum,
    appHead: Head,
  },
};
</script>
